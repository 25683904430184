import React from "react"
import { Link } from "gatsby"

const Breadcrumbs = ({ title, url  }) => {
	return (
		<div className="constrained">
			<nav className="preview__nav">
				<Link className="btn btn--cta" to={`/${url}`}>{`Back to ` + title }</Link>
			</nav>
		</div>
	)
}

export default Breadcrumbs;