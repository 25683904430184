import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import PageBanner from "../components/banners/pageBanner"
import Breadcrumbs from "../components/links/breadcrumbs"
import { getMonthName } from "../utils"

const Post = props => {
	const {
		data: {
			wpgraphql: { post }
		},
	} = props;

	const { title, content, date } = post;
	const marginClass = ( post.acfPage.narrowMargins ) ? 'is-narrow constrained content__main' : 'constrained content__main';

	const dateObject = new Date(date);
	const formattedDate = getMonthName(dateObject.getMonth()) + ' ' + dateObject.getDate() + ', ' + dateObject.getFullYear();

	return (
		<Layout
			showAlternateCta={post.acfPage.showAlternateCta}
			contentType={`post`}
		>
			<article>
				<PageBanner 
					title={title}
					subtitle={formattedDate}
					description={post.acfPage.seoDescription}
				/>
				<div id="article-content" className="entry-content page-content">
					<section className="content light">
						<Breadcrumbs title={`Blog`} url={`/blog`} />
						<div className={marginClass} dangerouslySetInnerHTML={{ __html: content }}>
						</div>
						<Breadcrumbs title={`Blog`} url={`/blog`} />
					</section>
				</div>
			</article>
		</Layout>
	)
}

export default Post;

export const pageQuery = graphql`
	query GET_POST($id: ID!) {
		wpgraphql {
			post(id: $id) {
				title(format: RENDERED)
				content(format: RENDERED)
				uri
				date
				acfPage {
					narrowMargins
					showAlternateCta
					seoDescription
			   	}
			}
		}
	}
`